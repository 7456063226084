import {Directive, forwardRef, inject, Input} from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {Observable, of} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {ContractDto, EmployeeDto} from "../../../generated-model/model";
import {environment} from "../../../environments/environment";
import {map, tap} from "rxjs/operators";

@Directive({
  selector: '[appOriginDirectorValid][ngModel]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => OriginDirectorValidDirective),
      multi: true
    }
  ]
})
export class OriginDirectorValidDirective implements AsyncValidator {
  private http = inject(HttpClient);
  @Input("appOriginDirectorValid")
  contract: ContractDto

  constructor() {
  }

  validate(control: AbstractControl<EmployeeDto, EmployeeDto>): Promise<ValidationErrors> | Observable<ValidationErrors> {
    // console.log(`appOriginDirectorValid control.value?.bureau.bu3=${control.value?.bureau.bu3} `, `this.contract.bureau.bu3=${this.contract.bureau.bu3}`)
    if(this.contract?.bureau?.bu3 == '1L210') {
      return of(null);
    }
    if (control.value && control.value?.bureau?.bu3 != this.contract?.bureau?.bu3) {
      return this.http.get(`${environment.serverUrl}/api/agent-mapping/employee/${control.value.id}/${this.contract.bureau.bureauId}`)
        .pipe(map(e => {
          if (!e) {
            return {appOriginDirectorValid: 'มีการเลือกผู้มีอำนาจลงนามข้ามเขต กรณีนี้จะต้องตั้งค่า ช่วยปฏิบัติงานในตำแหน่งผู้อำนวยการเขต โดยแอดมิน กรุณาติดต่อส่วนกลาง'}
          } else {
            return null;
          }
        }))
    }
    return of(null);
  }

  registerOnValidatorChange?(fn: () => void): void;

}
