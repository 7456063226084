import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AlertDialogService} from "../dialog/alert-dialog/alert-dialog.service";

export const IGNORE_424_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class Http424Interceptor implements HttpInterceptor {

  constructor(private alertDialogService: AlertDialogService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return req.context.get(IGNORE_424_ERROR) ? next.handle(req) : next.handle(req)
      .pipe(tap
        (event => {
          },
          (err: HttpErrorResponse) => {
            if (err.status == 424) {
              console.warn(err)
              this.alertDialogService.open({title: 'การเชื่อมต่อข้อมูลล้มเหลว', type: 'danger', isInnerHTML:true, content: `<div class="text-center">${err.error?.message}</div>`});
            }
          })
      );
  }


}
