import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {catchError, debounceTime, Observable, of} from "rxjs";
import {Directive, forwardRef, inject, Input, OnChanges, SimpleChanges} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {map, tap} from "rxjs/operators";
import {CheckBudgetResponse} from "../../../generated-model/model";

@Directive({
  selector: '[remainBudgetValid][ngModel]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => RemainBudgetValid),
      multi: true
    }
  ]
})
export class RemainBudgetValid implements AsyncValidator, OnChanges {

  @Input({alias: 'remainBudgetValid', required: true}) remainBudgetValid: boolean;
  @Input({alias: 'budget', required: true}) budget: number;
  private http = inject(HttpClient);
  private control!: AbstractControl;

  constructor() {
  }

  registerOnValidatorChange?(fn: () => void): void;

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    this.control = control;
    console.log('this.remainBudgetValid = ', this.remainBudgetValid)
    console.log('control.value = ', control.value)
    if (this.remainBudgetValid && control.value) {
      console.log('xxx this.budget', this.budget);
      if (!this.budget) {
        return of({requiredBudget: true});
      }
      return this.http.post(`${environment.serverUrl}/api/smart-money-transfer/check-budget?budgetCode=` + control.value?.budgetCode, {})
        .pipe(
          debounceTime(300),
          map((response: CheckBudgetResponse) => {
            if (response.remainingBudgetPlan <= this.budget) {
              return {remainBudgetValid: `งบประมาณในการดำเนินงานไม่เพียงพอ, เหลือ ${response.remainingBudgetPlan?.toFixed(2) || 0} บาท`}; //
            } else {
              return null;
            }
          }),
          tap(value => console.log('tap ', value)),
          catchError(() => null)
        );
    }
    return of(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['budget']?.currentValue) {
      this.budget = +changes['budget'].currentValue;
      if (this.control) {
        this.control.setErrors(undefined);
        this.control.updateValueAndValidity();
      }
    }
  }
}
